import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faEdit,
  faHospitalSymbol,
  faBirthdayCake,
  faEnvelope,
  faPhoneAlt,
  faTicketAlt,
  faSyncAlt,
  faNewspaper,
  faChevronLeft,
  faPercentage,
  faGift,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faEllipsisV,
  faTrophy,
  faHistory,
  faChevronDown,
  faChevronUp,
  faCalendarAlt,
  faPlus,
  faStar,
  faPencilAlt,
  faArrowAltCircleRight,
  faSignOutAlt,
  faCalendarCheck,
  faGifts,
  faQrcode,
  faInfoCircle,
  faTimes,
  faCamera,
  faHome,
  faCreditCard,
  faFilter,
  faCopy,
  faShare,
  faTrashAlt,
  faBars,
  faList,
  faCrown,
  faMinusCircle,
  faPlusCircle,
  faExpand,
  faUser,
  faSearch,
  faCircle,
  faArrowAltCircleLeft,
  faRedoAlt,
  faBuilding,
  faReceipt,
  faGlobe
} from "@fortawesome/free-solid-svg-icons";
import { faLine } from "@fortawesome/free-brands-svg-icons";

library.add(
  faChevronRight,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faEdit,
  faChevronLeft,
  faGift,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faHistory,
  faPlus,
  faStar,
  faHospitalSymbol,
  faBirthdayCake,
  faEnvelope,
  faPhoneAlt,
  faTicketAlt,
  faSyncAlt,
  faNewspaper,
  faPercentage,
  faEllipsisV,
  faPencilAlt,
  faTrophy,
  faChevronDown,
  faChevronUp,
  faCalendarAlt,
  faArrowAltCircleRight,
  faSignOutAlt,
  faCalendarCheck,
  faGifts,
  faQrcode,
  faInfoCircle,
  faTimes,
  faCamera,
  faGift,
  faHome,
  faCreditCard,
  faFilter,
  faLine,
  faCopy,
  faShare,
  faTrashAlt,
  faBars,
  faList,
  faCrown,
  faMinusCircle,
  faPlusCircle,
  faExpand,
  faUser,
  faSearch,
  faCircle,
  faArrowAltCircleLeft,
  faRedoAlt,
  faBuilding,
  faReceipt,
  faGlobe
);
